import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { db } from './firebase';
import './App.css';
import './CodeLogin.css'; // Added for specific styling
import logo from './images/logodownsized2.png';

function CodeLogin({ onAccess }) {
  const [code, setCode] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const masterCode = process.env.REACT_APP_MASTER_CODE; // Use environment variable for master code

  // Function to check if user info is complete
  const isUserInfoComplete = (userInfo) => {
    if (!userInfo) return false;
    const requiredFields = ['name', 'email', 'passportId']; // Add other required fields as needed
    return requiredFields.every((field) => userInfo[field] && userInfo[field].trim() !== '');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (code === masterCode) {
      navigate('/admin');
    } else {
      try {
        const journeysRef = collection(db, 'journeys');
        const q = query(journeysRef, where('code', '==', code));
        const querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
          const docSnap = querySnapshot.docs[0];
          const journeyData = docSnap.data();
          const journeyId = docSnap.id;

          if (!journeyData.paymentStatus) {
            // Redirect to payment page
            navigate('/payment', { state: { journeyId, journeyData } });
          } else if (!isUserInfoComplete(journeyData.userInfo)) {
            // Redirect to user info form
            navigate('/user-info', { state: { journeyData, journeyId } });
          } else {
            // Update the journey data in App.js
            onAccess(journeyData, journeyId);

            // Proceed to journey page
            navigate('/journey');
          }
        } else {
          setError('Invalid code');
        }
      } catch (error) {
        console.error('Error fetching journey data:', error);
        setError('Error fetching journey data');
      }
    }
  };

  return (
    <div className="outer-container">
      {/* Logo Image */}
      <img src={logo} alt="BackPass Logo" className="logo" />

      {/* Login Box */}
      <div className="code-container">
        <h2>Early Access</h2>
        <form onSubmit={handleSubmit}>
          <input
            type="text"
            value={code}
            onChange={(e) => setCode(e.target.value)}
            placeholder="Enter Your Code Here"
            required
          />
          <button type="submit">Push Me</button>
        </form>
        {error && <p style={{ color: 'red' }}>{error}</p>}
      </div>
    </div>
  );
}

export default CodeLogin;
