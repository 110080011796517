import React, { useEffect, useRef } from 'react';
import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';

const Globe = () => {
  const mountRef = useRef(null);

  useEffect(() => {
    if (!mountRef.current) return;

    const mountNode = mountRef.current;

    // === Scene Setup ===
    const scene = new THREE.Scene();

    // === Camera Setup ===
    const camera = new THREE.PerspectiveCamera(
      75,
      mountNode.clientWidth / mountNode.clientHeight,
      0.1,
      1000
    );
    camera.position.z = 2;

    // === Renderer Setup ===
    const renderer = new THREE.WebGLRenderer({ antialias: true });
    renderer.setSize(mountNode.clientWidth, mountNode.clientHeight);
    renderer.setPixelRatio(window.devicePixelRatio);
    mountNode.appendChild(renderer.domElement);

    // === OrbitControls ===
    const controls = new OrbitControls(camera, renderer.domElement);
    controls.enableDamping = true;
    controls.dampingFactor = 0.05;
    controls.enableZoom = true;
    controls.minDistance = 0.6; // Set the minimum zoom distance
    controls.maxDistance = 5; // Maximum zoom distance

    // === Lighting ===
    const ambientLight = new THREE.AmbientLight(0xffffff, 0.5);
    scene.add(ambientLight);

    const directionalLight = new THREE.DirectionalLight(0xffffff, 1);
    directionalLight.position.set(5, 3, 5);
    scene.add(directionalLight);

    // === Earth Texture ===
    const textureLoader = new THREE.TextureLoader();
    const earthTexture = textureLoader.load('/assets/earth-texture.jpg'); // Ensure this path is correct

    // === Globe ===
    const sphereGeometry = new THREE.SphereGeometry(0.5, 64, 64);
    const sphereMaterial = new THREE.MeshStandardMaterial({
      map: earthTexture,
      metalness: 0.4,
      roughness: 0.7,
    });
    const globeMesh = new THREE.Mesh(sphereGeometry, sphereMaterial);
    scene.add(globeMesh);

    // === Moon ===
    const moonTexture = textureLoader.load('/assets/moon-texture.jpg'); // Ensure you have this texture
    const moonGeometry = new THREE.SphereGeometry(0.1, 32, 32); // Smaller size for the moon
    const moonMaterial = new THREE.MeshStandardMaterial({
      map: moonTexture,
      metalness: 0.3,
      roughness: 0.8,
    });
    const moonMesh = new THREE.Mesh(moonGeometry, moonMaterial);
    scene.add(moonMesh);

    // Dynamically Position the Moon
    const updateMoonPosition = () => {
      const moonDistance = 1.5; // Distance from the globe
      const moonAngle = Math.PI / 4; // Adjust dynamically if needed
      const x = Math.cos(moonAngle) * moonDistance;
      const z = Math.sin(moonAngle) * moonDistance;
      moonMesh.position.set(x, 0, z); // Position relative to Earth
    };
    updateMoonPosition();

    // === Stars Background ===
    const createStars = () => {
      const starGeometry = new THREE.BufferGeometry();
      const starMaterial = new THREE.PointsMaterial({
        color: 0xffffff,
        size: 0.005,
      });

      const starVertices = [];
      for (let i = 0; i < 10000; i++) {
        const x = (Math.random() - 0.5) * 2000;
        const y = (Math.random() - 0.5) * 2000;
        const z = -Math.random() * 2000;
        starVertices.push(x, y, z);
      }

      starGeometry.setAttribute(
        'position',
        new THREE.Float32BufferAttribute(starVertices, 3)
      );

      const stars = new THREE.Points(starGeometry, starMaterial);
      scene.add(stars);
    };
    createStars();

    // === Animation Loop ===
    const animate = () => {
      requestAnimationFrame(animate);
      controls.update();
      renderer.render(scene, camera);
    };
    animate();

    // === Handle Resize ===
    const handleWindowResize = () => {
      const width = mountNode.clientWidth;
      const height = mountNode.clientHeight;
      renderer.setSize(width, height);
      camera.aspect = width / height;
      camera.updateProjectionMatrix();
    };
    window.addEventListener('resize', handleWindowResize);

    // === Cleanup ===
    return () => {
      window.removeEventListener('resize', handleWindowResize);
      if (mountNode.contains(renderer.domElement)) {
        mountNode.removeChild(renderer.domElement);
      }
      controls.dispose();
      renderer.dispose();
    };
  }, []);

  return (
    <div
      ref={mountRef}
      style={{
        width: '100vw',
        height: 'calc(100vh - 50px)',
        overflow: 'hidden',
        marginTop: '50px',
      }}
    />
  );
};

export default Globe;
