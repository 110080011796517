import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  collection,
  query,
  where,
  getDocs,
  getDoc,
  doc,
  updateDoc,
} from 'firebase/firestore';
import { db } from './firebase';
import DaySlot from './components/DaySlot';

function Journey({ journeyData: propJourneyData, journeyId: propJourneyId, setRouteNames, routeNames }) {
  // Use read-only states, since we don't actually update these values
  const [journeyData] = useState(propJourneyData);
  const [journeyId] = useState(propJourneyId);
  const { days, routeIds, startDate } = journeyData || {};

  const [hostelAssignments, setHostelAssignments] = useState([]);
  const [selectedDay, setSelectedDay] = useState(null);

  const [availableCities, setAvailableCities] = useState([]);
  const [availableHostels, setAvailableHostels] = useState([]);

  const [isCityModalOpen, setIsCityModalOpen] = useState(false);
  const [isHostelModalOpen, setIsHostelModalOpen] = useState(false);

  const [selectedCity, setSelectedCity] = useState(null);

  const navigate = useNavigate();

  const handleBackClick = () => {
    navigate('/');
  };

  // Function to initialize hostel assignments
  const initializeHostelAssignments = useCallback(async () => {
    try {
      if (journeyId) {
        const journeyRef = doc(db, 'journeys', journeyId);
        const journeySnap = await getDoc(journeyRef);

        if (journeySnap.exists()) {
          const data = journeySnap.data();
          setHostelAssignments(data.hostelAssignments || Array(days).fill(null));
        } else {
          setHostelAssignments(Array(days).fill(null));
        }
      } else {
        setHostelAssignments(Array(days).fill(null));
      }
    } catch (error) {
      console.error('Error initializing hostel assignments:', error);
    }
  }, [journeyId, days]);

  // Function to fetch route names
  const fetchRouteNames = useCallback(async () => {
    try {
      const routesRef = collection(db, 'routes');
      const routesQuery = query(routesRef, where('__name__', 'in', routeIds || []));
      const routesSnapshot = await getDocs(routesQuery);

      const routes = routesSnapshot.docs.map((doc) => doc.data().name);
      setRouteNames(routes);
    } catch (error) {
      console.error('Error fetching route names:', error);
    }
  }, [routeIds, setRouteNames]);

  // Initialize data when journeyData is available
  useEffect(() => {
    if (journeyData) {
      initializeHostelAssignments();
      fetchRouteNames();
    }
  }, [journeyData, initializeHostelAssignments, fetchRouteNames]);

  // Function to open city selection modal
  const openModal = async (dayIndex) => {
    setSelectedDay(dayIndex);
    await fetchAvailableCities();
    setIsCityModalOpen(true);
  };

  // Function to fetch available cities
  const fetchAvailableCities = async () => {
    try {
      const routesRef = collection(db, 'routes');
      const cityIdsSet = new Set();

      const routesQuery = query(routesRef, where('__name__', 'in', routeIds || []));
      const routesSnapshot = await getDocs(routesQuery);

      routesSnapshot.forEach((doc) => {
        const data = doc.data();
        if (Array.isArray(data.cityIds)) {
          data.cityIds.forEach((cityId) => cityIdsSet.add(cityId));
        }
      });

      const cityIds = Array.from(cityIdsSet);
      if (cityIds.length === 0) {
        setAvailableCities([]);
        return;
      }
      const citiesRef = collection(db, 'cities');
      const citiesQuery = query(citiesRef, where('__name__', 'in', cityIds));
      const citiesSnapshot = await getDocs(citiesQuery);

      const cities = citiesSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      setAvailableCities(cities);
    } catch (error) {
      console.error('Error fetching cities:', error);
    }
  };

  // Function to fetch available hostels based on city ID
  const fetchAvailableHostels = async (cityId) => {
    try {
      const hostelsRef = collection(db, 'hostels');
      const hostelsQuery = query(hostelsRef, where('cityId', '==', cityId));
      const hostelsSnapshot = await getDocs(hostelsQuery);

      const hostels = hostelsSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      setAvailableHostels(hostels);
    } catch (error) {
      console.error('Error fetching hostels:', error);
    }
  };

  // Function to handle hostel selection
  const handleHostelSelection = async (hostel) => {
    try {
      const hostelWithCity = {
        ...hostel,
        cityName: selectedCity?.name,
      };
      const newAssignments = [...hostelAssignments];
      newAssignments[selectedDay] = hostelWithCity;
      setHostelAssignments(newAssignments);

      // Update in Firebase
      if (journeyId) {
        const journeyRef = doc(db, 'journeys', journeyId);
        await updateDoc(journeyRef, {
          hostelAssignments: newAssignments,
        });
      }

      setIsHostelModalOpen(false);
    } catch (error) {
      console.error('Error saving hostel selection:', error);
    }
  };

  // Function to handle deselecting a hostel
  const handleDeselectHostel = async (dayIndex) => {
    try {
      const newAssignments = [...hostelAssignments];
      newAssignments[dayIndex] = null;
      setHostelAssignments(newAssignments);

      // Update in Firebase
      if (journeyId) {
        const journeyRef = doc(db, 'journeys', journeyId);
        await updateDoc(journeyRef, {
          hostelAssignments: newAssignments,
        });
      }
    } catch (error) {
      console.error('Error deselecting hostel:', error);
    }
  };

  // Function to calculate and format the date
  const calculateDate = (offset) => {
    if (!startDate) return `Day ${offset + 1}`;
    const date = new Date(startDate);
    date.setDate(date.getDate() + offset);
    const monthDay = date.toLocaleDateString(undefined, { month: 'long', day: 'numeric' });
    const weekday = date.toLocaleDateString(undefined, { weekday: 'long' });
    return `${monthDay} (${weekday})`;
  };

  // Function to send email when Save button is clicked
  const handleSaveJourney = async () => {
    // Guard: Don’t send the request if crucial fields are missing
    if (!journeyId || !journeyData || !hostelAssignments) {
      alert('Journey data is not fully loaded. Please try again in a moment.');
      return;
    }

    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/send-journey-email`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          journeyId,
          journeyData,
          hostelAssignments,
        }),
      });

      const responseText = await response.text();
      console.log('Response Text:', responseText);

      let data;
      try {
        data = JSON.parse(responseText); // Try parsing JSON if possible
      } catch (error) {
        console.warn('Response is not JSON:', responseText);
        data = { error: responseText }; // Treat as plain text error message
      }

      if (response.ok) {
        alert(
          'Journey saved and email sent successfully. I will let you know when the bookings are made. WhatsApp Me anytime at +1-214-315-6968.'
        );
      } else {
        alert(`Failed to send email: ${data.error || responseText}`);
      }
    } catch (error) {
      console.error('Error sending journey email:', error);
      alert('An error occurred while sending the email.');
    }
  };

  if (!journeyData || !journeyId) {
    return <p>Loading journey details...</p>;
  }

  return (
    <div
      style={{
        padding: '0px',
        maxHeight: '90vh',
        overflowY: 'auto',
        backgroundColor: 'rgba(255,255,255,0.0)',
        borderRadius: '0px',
      }}
    >
      {/* Display the route names above the back button */}
      <h2>Route: {routeNames.join(', ')}</h2>

      <button
        onClick={handleBackClick}
        style={{
          marginTop: '20px',
          marginBottom: '20px',
          padding: '10px 15px',
          backgroundColor: '#853a38',
          color: 'white',
          border: 'none',
          borderRadius: '5px',
          cursor: 'pointer',
        }}
      >
        Back
      </button>

      <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
        {Array.from({ length: days }).map((_, dayIndex) => (
          <DaySlot
            key={dayIndex}
            dayLabel={calculateDate(dayIndex)}
            assignedHostel={hostelAssignments[dayIndex]}
            onClick={() => openModal(dayIndex)}
            onDeselect={() => handleDeselectHostel(dayIndex)}
          />
        ))}
      </div>

      {/* Save Button */}
      <button
        onClick={handleSaveJourney}
        style={{
          marginTop: '20px',
          padding: '10px 15px',
          backgroundColor: 'green',
          color: 'white',
          border: 'none',
          borderRadius: '5px',
          cursor: 'pointer',
          marginBottom: '20px',
        }}
      >
        Press Me to Get Matthew to Book Stuff
      </button>

      {/* City Selection Modal */}
      {isCityModalOpen && (
        <div
          style={{
            position: 'fixed',
            top: '0',
            left: '0',
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
          onClick={() => setIsCityModalOpen(false)}
        >
          <div
            style={{
              backgroundColor: '#fff',
              padding: '20px',
              borderRadius: '8px',
              width: '300px',
              maxHeight: '80vh',
              overflowY: 'auto',
            }}
            onClick={(e) => e.stopPropagation()}
          >
            <h3>Select a City for {calculateDate(selectedDay)}</h3>
            {availableCities.map((city) => (
              <div key={city.id} style={{ marginBottom: '10px' }}>
                <button
                  onClick={async () => {
                    setSelectedCity(city);
                    await fetchAvailableHostels(city.id);
                    setIsCityModalOpen(false);
                    setIsHostelModalOpen(true);
                  }}
                  style={{
                    width: '100%',
                    padding: '10px',
                    backgroundColor: '#f0f0f0',
                    border: '1px solid #ddd',
                    borderRadius: '4px',
                    cursor: 'pointer',
                  }}
                >
                  {city.name}
                </button>
              </div>
            ))}
            <button
              onClick={() => setIsCityModalOpen(false)}
              style={{
                marginTop: '20px',
                padding: '10px 15px',
                backgroundColor: '#853a38',
                color: 'white',
                border: 'none',
                borderRadius: '5px',
                cursor: 'pointer',
                width: '100%',
              }}
            >
              Close
            </button>
          </div>
        </div>
      )}

      {/* Hostel Selection Modal */}
      {isHostelModalOpen && (
        <div
          style={{
            position: 'fixed',
            top: '0',
            left: '0',
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.0)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
          onClick={() => setIsHostelModalOpen(false)}
        >
          <div
            style={{
              backgroundColor: '#fff',
              padding: '20px',
              borderRadius: '8px',
              width: '300px',
              maxHeight: '80vh',
              overflowY: 'auto',
            }}
            onClick={(e) => e.stopPropagation()}
          >
            <h3>
              Select a Hostel in {selectedCity?.name} for {calculateDate(selectedDay)}
            </h3>
            {availableHostels.map((hostel) => (
              <div
                key={hostel.id}
                style={{
                  border: '1px solid #ddd',
                  padding: '10px',
                  marginBottom: '10px',
                  borderRadius: '5px',
                  cursor: 'pointer',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <h4>{hostel.name}</h4>
                <p>{hostel.notes}</p>
                <button
                  onClick={() => handleHostelSelection(hostel)}
                  style={{
                    backgroundColor: '#853a38',
                    color: 'white',
                    padding: '5px 10px',
                    border: 'none',
                    borderRadius: '4px',
                    cursor: 'pointer',
                    marginTop: '10px',
                  }}
                >
                  Select
                </button>
              </div>
            ))}
            <button
              onClick={() => setIsHostelModalOpen(false)}
              style={{
                marginTop: '20px',
                padding: '10px 15px',
                backgroundColor: '#853a38',
                color: 'white',
                border: 'none',
                borderRadius: '5px',
                cursor: 'pointer',
                width: '100%',
              }}
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default Journey;
