// src/UserInfoForm.js
import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { doc, updateDoc } from 'firebase/firestore';
import { db } from './firebase';
import logo from './images/logodownsized2.png'; // Adjusted path

function UserInfoForm({ onAccess }) {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { journeyData, journeyId } = state || {};

  const [formData, setFormData] = useState({
    name: '',
    nationality: '',
    address: '',
    phone: '',
    email: '',
    passportId: '',
  });
  const [error, setError] = useState('');

  useEffect(() => {
    if (journeyData && journeyData.userInfo) {
      setFormData((prev) => ({
        ...prev,
        ...journeyData.userInfo,
      }));
    }
  }, [journeyData]);

  if (!journeyData || !journeyId) {
    return <p>No journey data available.</p>;
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate the form data
    const requiredFields = ['name', 'email', 'passportId'];
    for (const field of requiredFields) {
      if (!formData[field] || formData[field].trim() === '') {
        setError('Please fill in all required fields.');
        return;
      }
    }

    try {
      const journeyRef = doc(db, 'journeys', journeyId);
      await updateDoc(journeyRef, {
        userInfo: formData,
      });

      const updatedJourneyData = { ...journeyData, userInfo: formData };

      // Update the journey data in App.js
      onAccess(updatedJourneyData, journeyId);

      // Proceed to the journey page
      navigate('/journey');
    } catch (err) {
      console.error('Error updating user info:', err);
      setError('Failed to save your information. Please try again.');
    }
  };

  return (
    <div className="user-info-form">
      {/* Back Button */}
      <button
        onClick={() => navigate(-1)}
        style={{
          marginBottom: '10px',
          padding: '5px 10px',
          backgroundColor: '#853a38',
          color: 'white',
          border: 'none',
          borderRadius: '5px',
          cursor: 'pointer',
        }}
      >
        Back
      </button>
<div>
      {/* Logo Image */}
      <img src={logo} alt="BackPass Logo" className="logo" />
      </div>
      <h2>Welcome to BackPass!</h2>
      <h4>We need this information to make your bookings.</h4>
      <form onSubmit={handleSubmit}>
        {/* Input fields */}
        <input
          type="text"
          name="name"
          placeholder="Full Name"
          value={formData.name}
          onChange={handleChange}
          required
        />
        <input
          type="text"
          name="nationality"
          placeholder="Nationality"
          value={formData.nationality}
          onChange={handleChange}
        />
        <input
          type="text"
          name="address"
          placeholder="Address"
          value={formData.address}
          onChange={handleChange}
        />
        <input
          type="tel"
          name="phone"
          placeholder="Phone Number"
          value={formData.phone}
          onChange={handleChange}
        />
        <input
          type="email"
          name="email"
          placeholder="Email Address"
          value={formData.email}
          onChange={handleChange}
          required
        />
        <input
          type="text"
          name="passportId"
          placeholder="Passport ID"
          value={formData.passportId}
          onChange={handleChange}
          required
        />

        <button type="submit">Push Me</button>
        {error && <p style={{ color: 'red' }}>{error}</p>}
      </form>
    </div>
  );
}

export default UserInfoForm;
