import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

// Components
import Header from './components/Header';
import CodeLogin from './CodeLogin';
import JourneyPage from './components/JourneyPage';
import PaymentPage from './PaymentPage';
import PaymentCancelled from './PaymentCancelled';
import AdminJourneyPage from './AdminJourneyPage';
import UserInfoForm from './UserInfoForm';
import GlobePage from './components/GlobePage';
import NotFoundPage from './404';

// Styles
import './App.css';

// Stripe Initialization
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

function AppContent({ onAccess, journeyData, journeyId }) {
  const location = useLocation(); // Get the current route

  return (
    <div className="App">
      {/* Render Header only if not on the Code Login screen */}
      {location.pathname !== '/' && <Header />}
      <div className="main-content">
        <Routes>
          <Route path="/" element={<CodeLogin onAccess={onAccess} />} />
          <Route
            path="/journey"
            element={<JourneyPage journeyData={journeyData} journeyId={journeyId} />}
          />
          <Route path="/payment" element={<PaymentPage />} />
          <Route path="/payment-cancelled" element={<PaymentCancelled />} />
          <Route path="/admin" element={<AdminJourneyPage />} />
          <Route path="/user-info" element={<UserInfoForm onAccess={onAccess} />} />
          <Route path="/globe" element={<GlobePage />} />
          {/* Catch-all route */}
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </div>
      <footer className="footer">
        <p>&copy; {new Date().getFullYear()} BackPass. All rights reserved.</p>
      </footer>
    </div>
  );
}

function App() {
  const [journeyData, setJourneyData] = useState(null);
  const [journeyId, setJourneyId] = useState(null);

  const onAccess = (data, id) => {
    setJourneyData(data);
    setJourneyId(id);
  };

  return (
    <Elements stripe={stripePromise}>
      <Router>
        <AppContent onAccess={onAccess} journeyData={journeyData} journeyId={journeyId} />
      </Router>
    </Elements>
  );
}

export default App;
