// src/AdminJourneyPage.js
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { db } from './firebase'; // Assuming firebase.js is in src
import {
  collection,
  getDocs,
  addDoc,
  updateDoc,
  deleteDoc,
  doc,
} from 'firebase/firestore';

function AdminJourneyPage() {
  // State variables for each collection
  const [journeys, setJourneys] = useState([]);
  const [hostels, setHostels] = useState([]);
  const [cities, setCities] = useState([]);
  const [routes, setRoutes] = useState([]);

  // Selected items for editing
  const [selectedJourney, setSelectedJourney] = useState({
    id: '',
    code: '',
    name: '',
    days: 0,
    cost: 0,
    paymentStatus: false,
    routeIds: [],
    startDate: '',
    userInfo: {
      name: '',
      nationality: '',
      address: '',
      phone: '',
      email: '',
      passportId: '',
    },
  });
  const [selectedHostel, setSelectedHostel] = useState({
    id: '',
    name: '',
    cityId: '',
    link: '',
    notes: '',
    contact: '',
  });
  const [selectedCity, setSelectedCity] = useState({
    id: '',
    name: '',
    hostelIds: [],
    notes: '',
  });
  const [selectedRoute, setSelectedRoute] = useState({
    id: '',
    name: '',
    cityIds: [],
    notes: '',
  });

  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleBackClick = () => {
    navigate('/');
  };

  useEffect(() => {
    const fetchData = async () => {
      await fetchCities();
      await fetchHostels();
      await fetchRoutes();
      await fetchJourneys();
    };

    fetchData();
  }, []);

  // Fetch functions for each collection
  const fetchJourneys = async () => {
    try {
      const journeyCollection = collection(db, 'journeys');
      const journeySnapshot = await getDocs(journeyCollection);
      setJourneys(
        journeySnapshot.docs.map((doc) => {
          const data = doc.data();
          return {
            ...data,
            id: doc.id,
          };
        })
      );
    } catch (error) {
      console.error('Error fetching journeys:', error);
      setError('Failed to fetch journeys');
    }
  };

  const fetchHostels = async () => {
    try {
      const hostelCollection = collection(db, 'hostels');
      const hostelSnapshot = await getDocs(hostelCollection);

      const cityCollection = collection(db, 'cities');
      const citySnapshot = await getDocs(cityCollection);
      const citiesMap = {};
      citySnapshot.docs.forEach((doc) => {
        const data = doc.data();
        citiesMap[doc.id] = data.name;
      });

      const hostelsWithCityNames = hostelSnapshot.docs.map((doc) => {
        const hostelData = doc.data();
        return {
          ...hostelData,
          id: doc.id,
          cityName: citiesMap[hostelData.cityId] || 'Unknown City',
        };
      });

      setHostels(hostelsWithCityNames);
    } catch (error) {
      console.error('Error fetching hostels:', error);
      setError('Failed to fetch hostels');
    }
  };

  const fetchCities = async () => {
    try {
      const cityCollection = collection(db, 'cities');
      const citySnapshot = await getDocs(cityCollection);
      setCities(citySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    } catch (error) {
      console.error('Error fetching cities:', error);
      setError('Failed to fetch cities');
    }
  };

  const fetchRoutes = async () => {
    try {
      const routeCollection = collection(db, 'routes');
      const routeSnapshot = await getDocs(routeCollection);
      setRoutes(
        routeSnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
      );
    } catch (error) {
      console.error('Error fetching routes:', error);
      setError('Failed to fetch routes');
    }
  };

  // Input change handlers for each entity
  const handleJourneyInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setSelectedJourney((prev) => ({
      ...prev,
      [name]:
        type === 'checkbox'
          ? checked
          : name === 'days' || name === 'cost'
          ? Number(value)
          : value,
    }));
  };

  const handleUserInfoInputChange = (e) => {
    const { name, value } = e.target;
    setSelectedJourney((prev) => ({
      ...prev,
      userInfo: {
        ...prev.userInfo,
        [name]: value,
      },
    }));
  };

  const handleHostelInputChange = (e) => {
    const { name, value } = e.target;
    setSelectedHostel((prev) => ({ ...prev, [name]: value }));
  };

  const handleCityInputChange = (e) => {
    const { name, value } = e.target;
    setSelectedCity((prev) => ({ ...prev, [name]: value }));
  };

  const handleRouteInputChange = (e) => {
    const { name, value } = e.target;
    setSelectedRoute((prev) => ({ ...prev, [name]: value }));
  };

  // Selection handlers for multi-select fields
  const handleCityHostelSelection = (e) => {
    const options = e.target.options;
    const selectedHostelIds = [];
    for (let i = 0; i < options.length; i++) {
      if (options[i].selected) {
        selectedHostelIds.push(options[i].value);
      }
    }
    setSelectedCity((prev) => ({ ...prev, hostelIds: selectedHostelIds }));
  };

  const handleRouteCitySelection = (e) => {
    const options = e.target.options;
    const selectedCityIds = [];
    for (let i = 0; i < options.length; i++) {
      if (options[i].selected) {
        selectedCityIds.push(options[i].value);
      }
    }
    setSelectedRoute((prev) => ({ ...prev, cityIds: selectedCityIds }));
  };

  const handleJourneyRouteSelection = (e) => {
    const options = e.target.options;
    const selectedRouteIds = [];
    for (let i = 0; i < options.length; i++) {
      if (options[i].selected) {
        selectedRouteIds.push(options[i].value);
      }
    }
    setSelectedJourney((prev) => ({ ...prev, routeIds: selectedRouteIds }));
  };

  // Save (Add/Edit) functions for each entity
  const handleSaveJourneyChanges = async () => {
    if (!selectedJourney.code) {
      setError('Journey code is required');
      return;
    }

    try {
      const journeyData = {
        code: selectedJourney.code,
        name: selectedJourney.name,
        days: Number(selectedJourney.days),
        cost: Number(selectedJourney.cost),
        paymentStatus: selectedJourney.paymentStatus,
        routeIds: selectedJourney.routeIds,
        startDate: selectedJourney.startDate,
        userInfo: selectedJourney.userInfo,
      };

      if (selectedJourney.id) {
        const journeyRef = doc(db, 'journeys', selectedJourney.id);
        await updateDoc(journeyRef, journeyData);
        alert('Journey updated successfully');
      } else {
        await addDoc(collection(db, 'journeys'), journeyData);
        alert('Journey added successfully');
      }

      // Reset selectedJourney
      setSelectedJourney({
        id: '',
        code: '',
        name: '',
        days: 0,
        cost: 0,
        paymentStatus: false,
        routeIds: [],
        startDate: '',
        userInfo: {
          name: '',
          nationality: '',
          address: '',
          phone: '',
          email: '',
          passportId: '',
        },
      });
      fetchJourneys();
    } catch (error) {
      console.error('Error updating journey:', error);
      setError('Failed to save journey');
    }
  };

  const handleSaveHostelChanges = async () => {
    if (!selectedHostel.name || !selectedHostel.cityId) {
      setError('Hostel name and city are required');
      return;
    }

    try {
      const hostelData = {
        name: selectedHostel.name,
        cityId: selectedHostel.cityId,
        link: selectedHostel.link,
        notes: selectedHostel.notes,
        contact: selectedHostel.contact,
      };

      if (selectedHostel.id) {
        const hostelRef = doc(db, 'hostels', selectedHostel.id);
        await updateDoc(hostelRef, hostelData);
        alert('Hostel updated successfully');
      } else {
        await addDoc(collection(db, 'hostels'), hostelData);
        alert('Hostel added successfully');
      }

      setSelectedHostel({
        id: '',
        name: '',
        cityId: '',
        link: '',
        notes: '',
        contact: '',
      });
      fetchHostels();
    } catch (error) {
      console.error('Error saving hostel:', error);
      setError('Failed to save hostel');
    }
  };

  const handleSaveCityChanges = async () => {
    if (!selectedCity.name) {
      setError('City name is required');
      return;
    }

    try {
      const cityData = {
        name: selectedCity.name,
        hostelIds: selectedCity.hostelIds,
        notes: selectedCity.notes,
      };

      if (selectedCity.id) {
        const cityRef = doc(db, 'cities', selectedCity.id);
        await updateDoc(cityRef, cityData);
        alert('City updated successfully');
      } else {
        await addDoc(collection(db, 'cities'), cityData);
        alert('City added successfully');
      }

      setSelectedCity({ id: '', name: '', hostelIds: [], notes: '' });
      fetchCities();
    } catch (error) {
      console.error('Error saving city:', error);
      setError('Failed to save city');
    }
  };

  const handleSaveRouteChanges = async () => {
    if (!selectedRoute.name) {
      setError('Route name is required');
      return;
    }

    try {
      const routeData = {
        name: selectedRoute.name,
        cityIds: selectedRoute.cityIds,
        notes: selectedRoute.notes,
      };

      if (selectedRoute.id) {
        const routeRef = doc(db, 'routes', selectedRoute.id);
        await updateDoc(routeRef, routeData);
        alert('Route updated successfully');
      } else {
        await addDoc(collection(db, 'routes'), routeData);
        alert('Route added successfully');
      }

      setSelectedRoute({ id: '', name: '', cityIds: [], notes: '' });
      fetchRoutes();
    } catch (error) {
      console.error('Error saving route:', error);
      setError('Failed to save route');
    }
  };

  // Delete functions for each entity
  const handleDeleteJourney = async (id) => {
    try {
      await deleteDoc(doc(db, 'journeys', id));
      alert('Journey deleted successfully');
      fetchJourneys();
    } catch (error) {
      console.error('Error deleting journey:', error);
      setError('Failed to delete journey');
    }
  };

  const handleDeleteHostel = async (id) => {
    try {
      await deleteDoc(doc(db, 'hostels', id));
      alert('Hostel deleted successfully');
      fetchHostels();
    } catch (error) {
      console.error('Error deleting hostel:', error);
      setError('Failed to delete hostel');
    }
  };

  const handleDeleteCity = async (id) => {
    try {
      await deleteDoc(doc(db, 'cities', id));
      alert('City deleted successfully');
      fetchCities();
    } catch (error) {
      console.error('Error deleting city:', error);
      setError('Failed to delete city');
    }
  };

  const handleDeleteRoute = async (id) => {
    try {
      await deleteDoc(doc(db, 'routes', id));
      alert('Route deleted successfully');
      fetchRoutes();
    } catch (error) {
      console.error('Error deleting route:', error);
      setError('Failed to delete route');
    }
  };

  // Edit functions for each entity
  const handleEditJourney = (journey) => {
    // Ensure userInfo exists in selectedJourney
    setSelectedJourney({
      ...journey,
      userInfo: journey.userInfo || {
        name: '',
        nationality: '',
        address: '',
        phone: '',
        email: '',
        passportId: '',
      },
    });
  };

  const handleEditHostel = (hostel) => {
    setSelectedHostel(hostel);
  };

  const handleEditCity = (city) => {
    setSelectedCity(city);
  };

  const handleEditRoute = (route) => {
    setSelectedRoute(route);
  };

  // Function to fetch associated data for display
  const getAssociatedRoutes = (journey) => {
    if (Array.isArray(journey.routeIds)) {
      return journey.routeIds
        .map((routeId) => {
          const route = routes.find((r) => r.id === routeId);
          return route ? route.name : 'Unknown Route';
        })
        .join(', ');
    }
    return 'No Routes';
  };

  const getAssociatedHostels = (city) => {
    if (Array.isArray(city.hostelIds)) {
      return city.hostelIds
        .map((hostelId) => {
          const hostel = hostels.find((h) => h.id === hostelId);
          return hostel ? hostel.name : 'Unknown Hostel';
        })
        .join(', ');
    }
    return 'No Hostels';
  };

  const getAssociatedCities = (route) => {
    if (Array.isArray(route.cityIds)) {
      return route.cityIds
        .map((cityId) => {
          const city = cities.find((c) => c.id === cityId);
          return city ? city.name : 'Unknown City';
        })
        .join(', ');
    }
    return 'No Cities';
  };

  return (
    <div
      style={{
        padding: '20px',
        backgroundColor: 'white',
        maxHeight: '90vh',
        overflowY: 'auto',
      }}
    >
      <h2>Admin Page</h2>
      <button
        onClick={handleBackClick}
        style={{
          marginBottom: '20px',
          padding: '10px 15px',
          backgroundColor: '#007bff',
          color: 'white',
          border: 'none',
          borderRadius: '5px',
          cursor: 'pointer',
        }}
      >
        Back to Home
      </button>

      {error && <p style={{ color: 'red' }}>{error}</p>}

      {/* Journeys Section */}
      <h3>Manage Journeys</h3>
      <div>
        <label>
          Journey Code:
          <input
            type="text"
            name="code"
            value={selectedJourney.code}
            onChange={handleJourneyInputChange}
            required
          />
        </label>
        <label>
          Name:
          <input
            type="text"
            name="name"
            value={selectedJourney.name}
            onChange={handleJourneyInputChange}
            required
          />
        </label>
        <label>
          Days:
          <input
            type="number"
            name="days"
            value={selectedJourney.days}
            onChange={handleJourneyInputChange}
            required
          />
        </label>
        <label>
          Cost:
          <input
            type="number"
            name="cost"
            value={selectedJourney.cost}
            onChange={handleJourneyInputChange}
            required
          />
        </label>
        <label>
          Payment Status:
          <input
            type="checkbox"
            name="paymentStatus"
            checked={selectedJourney.paymentStatus}
            onChange={handleJourneyInputChange}
          />
        </label>
        <label>
          Routes:
          <select
            name="routeIds"
            value={selectedJourney.routeIds}
            onChange={handleJourneyRouteSelection}
            multiple
          >
            {routes.map((route) => (
              <option key={route.id} value={route.id}>
                {route.name}
              </option>
            ))}
          </select>
        </label>
        <label>
          Start Date:
          <input
            type="date"
            name="startDate"
            value={selectedJourney.startDate}
            onChange={handleJourneyInputChange}
            required
          />
        </label>

        {/* User Information Fields */}
        <h4>User Information</h4>
        <label>
          Name:
          <input
            type="text"
            name="name"
            value={selectedJourney.userInfo.name}
            onChange={handleUserInfoInputChange}
          />
        </label>
        <label>
          Nationality:
          <input
            type="text"
            name="nationality"
            value={selectedJourney.userInfo.nationality}
            onChange={handleUserInfoInputChange}
          />
        </label>
        <label>
          Address:
          <input
            type="text"
            name="address"
            value={selectedJourney.userInfo.address}
            onChange={handleUserInfoInputChange}
          />
        </label>
        <label>
          Phone:
          <input
            type="text"
            name="phone"
            value={selectedJourney.userInfo.phone}
            onChange={handleUserInfoInputChange}
          />
        </label>
        <label>
          Email:
          <input
            type="email"
            name="email"
            value={selectedJourney.userInfo.email}
            onChange={handleUserInfoInputChange}
          />
        </label>
        <label>
          Passport ID:
          <input
            type="text"
            name="passportId"
            value={selectedJourney.userInfo.passportId}
            onChange={handleUserInfoInputChange}
          />
        </label>

        <button onClick={handleSaveJourneyChanges}>
          {selectedJourney.id ? 'Update Journey' : 'Add Journey'}
        </button>
      </div>

      <h4>Current Journeys</h4>
      <table>
        <thead>
          <tr>
            <th>Code</th>
            <th>Name</th>
            <th>Days</th>
            <th>Cost</th>
            <th>Payment Status</th>
            <th>Routes</th>
            <th>Start Date</th>
            <th>User Info</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {journeys.map((journey) => {
            const associatedRoutes = getAssociatedRoutes(journey);
            const userInfo = journey.userInfo || {};

            return (
              <tr key={journey.id}>
                <td>{journey.code}</td>
                <td>{journey.name}</td>
                <td>{journey.days}</td>
                <td>{journey.cost}</td>
                <td>{journey.paymentStatus ? 'Paid' : 'Unpaid'}</td>
                <td>{associatedRoutes}</td>
                <td>{journey.startDate}</td>
                <td>
                  {userInfo.name ? (
                    <div>
                      <p><strong>Name:</strong> {userInfo.name}</p>
                      <p><strong>Email:</strong> {userInfo.email}</p>
                      <p><strong>Phone:</strong> {userInfo.phone}</p>
                      <p><strong>Nationality:</strong> {userInfo.nationality}</p>
                      <p><strong>Address:</strong> {userInfo.address}</p>
                      <p><strong>Passport ID:</strong> {userInfo.passportId}</p>
                    </div>
                  ) : (
                    'No User Info'
                  )}
                </td>
                <td>
                  <button onClick={() => handleEditJourney(journey)}>Edit</button>
                  <button onClick={() => handleDeleteJourney(journey.id)}>
                    Delete
                  </button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>

      {/* Hostels Section */}
      <h3>Manage Hostels</h3>
      <div>
        <label>
          Hostel Name:
          <input
            type="text"
            name="name"
            value={selectedHostel.name}
            onChange={handleHostelInputChange}
            required
          />
        </label>
        <label>
          City:
          <select
            name="cityId"
            value={selectedHostel.cityId}
            onChange={handleHostelInputChange}
            required
          >
            <option value="">Select City</option>
            {cities.map((city) => (
              <option key={city.id} value={city.id}>
                {city.name}
              </option>
            ))}
          </select>
        </label>
        <label>
          Link:
          <input
            type="text"
            name="link"
            value={selectedHostel.link}
            onChange={handleHostelInputChange}
          />
        </label>
        <label>
          Notes:
          <input
            type="text"
            name="notes"
            value={selectedHostel.notes}
            onChange={handleHostelInputChange}
          />
        </label>
        <label>
          Contact:
          <input
            type="text"
            name="contact"
            value={selectedHostel.contact}
            onChange={handleHostelInputChange}
          />
        </label>
        <button onClick={handleSaveHostelChanges}>
          {selectedHostel.id ? 'Update Hostel' : 'Add Hostel'}
        </button>
      </div>

      <h4>Current Hostels</h4>
      <table>
        <thead>
          <tr>
            <th>Name</th>
            <th>City</th>
            <th>Link</th>
            <th>Notes</th>
            <th>Contact</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {hostels.map((hostel) => {
            const city = cities.find((c) => c.id === hostel.cityId);
            return (
              <tr key={hostel.id}>
                <td>{hostel.name}</td>
                <td>{city ? city.name : 'Unknown City'}</td>
                <td>
                  <a href={hostel.link} target="_blank" rel="noopener noreferrer">
                    Link
                  </a>
                </td>
                <td>{hostel.notes}</td>
                <td>{hostel.contact}</td>
                <td>
                  <button onClick={() => handleEditHostel(hostel)}>Edit</button>
                  <button onClick={() => handleDeleteHostel(hostel.id)}>
                    Delete
                  </button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>

      {/* Cities Section */}
      <h3>Manage Cities</h3>
      <div>
        <label>
          City Name:
          <input
            type="text"
            name="name"
            value={selectedCity.name}
            onChange={handleCityInputChange}
            required
          />
        </label>
        <label>
          Hostels:
          <select
            name="hostelIds"
            value={selectedCity.hostelIds}
            onChange={handleCityHostelSelection}
            multiple
          >
            {hostels.map((hostel) => (
              <option key={hostel.id} value={hostel.id}>
                {hostel.name}
              </option>
            ))}
          </select>
        </label>
        <label>
          Notes:
          <input
            type="text"
            name="notes"
            value={selectedCity.notes}
            onChange={handleCityInputChange}
          />
        </label>
        <button onClick={handleSaveCityChanges}>
          {selectedCity.id ? 'Update City' : 'Add City'}
        </button>
      </div>

      <h4>Current Cities</h4>
      <table>
        <thead>
          <tr>
            <th>Name</th>
            <th>Hostels</th>
            <th>Notes</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {cities.map((city) => {
            const associatedHostels = getAssociatedHostels(city);

            return (
              <tr key={city.id}>
                <td>{city.name}</td>
                <td>{associatedHostels}</td>
                <td>{city.notes}</td>
                <td>
                  <button onClick={() => handleEditCity(city)}>Edit</button>
                  <button onClick={() => handleDeleteCity(city.id)}>Delete</button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>

      {/* Routes Section */}
      <h3>Manage Routes</h3>
      <div>
        <label>
          Route Name:
          <input
            type="text"
            name="name"
            value={selectedRoute.name}
            onChange={handleRouteInputChange}
            required
          />
        </label>
        <label>
          Cities:
          <select
            name="cityIds"
            value={selectedRoute.cityIds}
            onChange={handleRouteCitySelection}
            multiple
          >
            {cities.map((city) => (
              <option key={city.id} value={city.id}>
                {city.name}
              </option>
            ))}
          </select>
        </label>
        <label>
          Notes:
          <input
            type="text"
            name="notes"
            value={selectedRoute.notes}
            onChange={handleRouteInputChange}
          />
        </label>
        <button onClick={handleSaveRouteChanges}>
          {selectedRoute.id ? 'Update Route' : 'Add Route'}
        </button>
      </div>

      <h4>Current Routes</h4>
      <table>
        <thead>
          <tr>
            <th>Name</th>
            <th>Cities</th>
            <th>Notes</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {routes.map((route) => {
            const associatedCities = getAssociatedCities(route);

            return (
              <tr key={route.id}>
                <td>{route.name}</td>
                <td>{associatedCities}</td>
                <td>{route.notes}</td>
                <td>
                  <button onClick={() => handleEditRoute(route)}>Edit</button>
                  <button onClick={() => handleDeleteRoute(route.id)}>Delete</button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

export default AdminJourneyPage;
