// src/components/JourneyPage.js
import React, { useEffect, useState } from 'react';
import Journey from '../Journey'; // Updated import path
import { useLocation } from 'react-router-dom';

function JourneyPage({ journeyData: propJourneyData, journeyId: propJourneyId }) {
  const location = useLocation();
  const [journeyData, setJourneyData] = useState(propJourneyData);
  const [journeyId, setJourneyId] = useState(propJourneyId);
  const [routeNames, setRouteNames] = useState([]); // State for route names

  useEffect(() => {
    // If journeyData and journeyId are not provided via props, attempt to fetch them
    if (!propJourneyData || !propJourneyId) {
      const query = new URLSearchParams(location.search);
      const sessionId = query.get('session_id');

      if (sessionId) {
        fetch(`${process.env.REACT_APP_BACKEND_URL}/checkout-session?sessionId=${sessionId}`)
          .then((res) => res.json())
          .then(async (session) => {
            const journeyIdFromSession = session.metadata.journeyId;
            setJourneyId(journeyIdFromSession);

            try {
              const response = await fetch(
                `${process.env.REACT_APP_BACKEND_URL}/api/journey/${journeyIdFromSession}`
              );
              if (!response.ok) {
                throw new Error(`Server error: ${response.statusText}`);
              }
              const data = await response.json();
              setJourneyData(data);
            } catch (error) {
              console.error('Error fetching journey data:', error);
              setJourneyData(null); // Set journeyData to null on error
            }
          })
          .catch((error) => {
            console.error('Error fetching session data:', error);
            setJourneyData(null); // Set journeyData to null on error
          });
      } else {
        // No sessionId and no journeyData/Id provided, display error message
        setJourneyData(null);
      }
    }
  }, [location.search, propJourneyData, propJourneyId]);

  // Update local state when props change
  useEffect(() => {
    if (propJourneyData && propJourneyId) {
      setJourneyData(propJourneyData);
      setJourneyId(propJourneyId);
    }
  }, [propJourneyData, propJourneyId]);

  if (!journeyData || !journeyId) {
    return <p>Please log in with a valid code to access the journey page.</p>;
  }

  return (
    <div style={{ padding: '10px' }}>
      <Journey
        journeyData={journeyData}
        journeyId={journeyId}
        routeNames={routeNames} // Pass routeNames to Journey
        setRouteNames={setRouteNames} // Pass setRouteNames to Journey
      />
    </div>
  );
}

export default JourneyPage;
