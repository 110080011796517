import React from 'react';
import Globe from './Globe';

const GlobePage = () => {
  return (
    <div>
      <div style={{ padding: '0px', textAlign: 'center' }}>
        {/* Provide accessible content inside the heading */}
        <h1>Explore the Globe</h1>
      </div>
      <Globe />
    </div>
  );
};

export default GlobePage;
